@import '~normalize.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/popover2/lib/css/blueprint-popover2.css';

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;600&display=swap');

body {
  font-family: 'Fira Sans', sans-serif;
  overflow-y: scroll;
}

html,
body,
#react-root {
  height: 100%;
}

/* Blueprint overrides */
.bp3-heading {
  line-height: initial !important;
}

/* Custom classes where we can't pass style={} to nodes */

.tooltip {
  max-width: 450px;
}
